/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { OutboundDocumentTypeDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class OutboundDocumentTypeDataAccessService {
	private api = inject(ApiService);

	// GET @ outbound/documents/types/
	getAll(query: EntityQueryDto<OutboundDocumentTypeDto>) {
		return this.api.post<OutboundDocumentTypeDto[]>(`OutboundDocumentType.getAll`, { body: { data: query } });
	}

	// GET @ outbound/documents/types/:id
	getById(id: number, query: FindOptionsDto<OutboundDocumentTypeDto>) {
		return this.api.post<OutboundDocumentTypeDto>(`OutboundDocumentType.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	count(query: FilterQueryDto<OutboundDocumentTypeDto>) {
		return this.api.post<number>(`OutboundDocumentType.count`, { body: { data: query } });
	}
}
