/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { ApiService } from '@nest/common/frontend/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { OutboundDocumentLineDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class OutboundDocumentLineDataAccessService {
	private api = inject(ApiService);

	// GET @ outbound-documents/lines/
	getAll(query: EntityQueryDto<OutboundDocumentLineDto>) {
		return this.api.post<OutboundDocumentLineDto[]>(`outboundDocumentLines.getAll`, { body: { data: query } });
	}

	// GET @ outbound-documents/lines/:id
	getById(id: number, query: FindOptionsDto<OutboundDocumentLineDto>) {
		return this.api.post<OutboundDocumentLineDto>(`outboundDocumentLines.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	count(query: FilterQueryDto<OutboundDocumentLineDto>) {
		return this.api.post<number>(`outboundDocumentLines.count`, { body: { data: query } });
	}
}
