import { APP_INITIALIZER, inject } from '@angular/core';
import { TimeWindowPathRendererComponent } from '@nest/domain/common/frontend/entity-presentation';
import type { OutboundDocumentDto, OutboundDocumentLineDto } from '@nest/domain/entities-metadata/dtos';
import { OutboundDocumentEntityName, OutboundDocumentLineEntityName } from '@nest/domain/entities-metadata/dtos';
import { OutboundDocumentDataAccessService, OutboundDocumentLineDataAccessService } from '@nest/domain/erp/outbound-documents/frontend/data-access';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';
import { OutboundDocumentStateColorEntityPathRendererComponent } from './outbound-document-state-color-path-render.component';

const paths = autoPathsFor<OutboundDocumentDto>();

export const OutboundDocumentDefaultElements = fieldsToElements(paths(['createdAt', 'warehouse.name', 'documentType.name', 'documentNumber', 'state', 'buyer.name', 'lines']));
export const OutboundDocumentLineDefaultElements = fieldsToElements(autoPathsFor<OutboundDocumentLineDto>()(['item.code', 'item.name', 'quantity', 'price.netto']));

export function provideOutboundDocumentsEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				let outboundDocumentDataAccess = inject(OutboundDocumentDataAccessService);
				let outboundDeliveryLineDataAccess = inject(OutboundDocumentLineDataAccessService);

				return () => {
					entityMetadata.registerEntity({
						entityName: OutboundDocumentEntityName,
						dataAccess: outboundDocumentDataAccess,
						defaultElements: OutboundDocumentDefaultElements
					});

					entityMetadata.registerEntity({
						entityName: OutboundDocumentLineEntityName,
						dataAccess: outboundDeliveryLineDataAccess,
						defaultElements: OutboundDocumentLineDefaultElements
					});

					entityMetadata.registerEntityPathRenderer(OutboundDocumentEntityName, paths(['state']), OutboundDocumentStateColorEntityPathRendererComponent);
					entityMetadata.registerEntityPathRenderer(OutboundDocumentEntityName, paths(['deliveryShipmentTimeWindows']), TimeWindowPathRendererComponent);
				};
			}
		}
	];
}
