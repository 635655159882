/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { InvoiceDto, OutboundDocumentDto } from '@nest/domain/entities-metadata/dtos';
import { SendOutboundDocumentBody, SendtoWmsBody, ToCorrectionDocumentBody } from '@nest/domain/outbound-documents/shared';

@Injectable({
	providedIn: 'root'
})
export class OutboundDocumentDataAccessService {
	private api = inject(ApiService);

	// GET @ outbound-documents/
	getAll(query: EntityQueryDto<OutboundDocumentDto>) {
		return this.api.post<OutboundDocumentDto[]>(`outboundDocuments.getAll`, { body: { data: query } });
	}

	// GET @ outbound-documents/:id
	getById(id: number, query: FindOptionsDto<OutboundDocumentDto>) {
		return this.api.post<OutboundDocumentDto>(`outboundDocuments.getById`, { body: { data: { id, ...query } } });
	}

	count(query: FilterQueryDto<OutboundDocumentDto>) {
		return this.api.post<number>(`outboundDocuments.count`, { body: { data: query } });
	}

	// POST @ outbound-documents/:id/send
	sendOutboundDocument(id: number, body: SendOutboundDocumentBody) {
		return this.api.post<void>(`outboundDocuments.sendOutboundDocument`, { body: { data: { id, ...body } } });
	}

	// POST @ outbound-documents/toCorrectionDocument
	toCorrectionDocument(body: ToCorrectionDocumentBody) {
		return this.api.post<any>(`outboundDocuments.toCorrectionDocument`, { body: { data: body } });
	}

	// POST @ outbound-documents/:id/generate-invoice
	generateInvoice(id: number) {
		return this.api.post<InvoiceDto>(`outboundDocuments.generateInvoice`, { body: { data: { id } } });
	}

	// POST @ outbound-documents/generate-batch-invoices
	generateBatchInvoices(body: any) {
		return this.api.post<InvoiceDto[]>(`outboundDocuments.generateBatchInvoices`, { body: { data: body } });
	}

	// POST @ outbound-documents/
	// create(body: OutboundDocumentCreateData, query: SaveQuery) {
	// 	return this.api.post<OutboundDocumentDto>(`outboundDocuments.create`, { body: { data: { ...body, ...query } } });
	// }

	// // PUT @ outbound-documents/:id?
	// update(param: any, body: OutboundDocumentUpdateData, query: SaveQuery) {
	// 	return this.api.post<OutboundDocumentDto>(`outboundDocuments.update`, { body: { data: { ...param, ...body, ...query } } });
	// }

	// POST @ outbound-documents/:id/send-to-wms
	sendToWms(id: number, body: SendtoWmsBody) {
		return this.api.post<OutboundDocumentDto>(`outboundDocuments.sendToWms`, { body: { data: { id, ...body } } });
	}

	// POST @ outbound-documents/:id/dispatch
	dispatch(id: number) {
		return this.api.post<void>(`outboundDocuments.dispatch`, { body: { data: { id } } });
	}

	// POST @ outbound-documents/:id/cancel
	cancel(id: number) {
		return this.api.post<void>(`outboundDocuments.cancel`, { body: { data: { id } } });
	}
}
